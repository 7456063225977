<template>
    <div class="helpcenter">
        <div class="banner"> 
            <div class="banner-container">
                <div>
                    <h2>帮助中心</h2>
                    <h4></h4>
                    <p>告诉我们您的想法，我们的专业服务团队随时准备为您解答疑惑。</p>
                </div>
                <img src="../assets/img/help.png" alt="#">
            </div>
        </div>
        <div class="content">
            <div class="content-container" @click="handleToDetail">
                <div>
                    <img src="../assets/img/help_1.png" alt="">
                    <h2>新手入门</h2>
                    <h4></h4>
                    <p>小小标题</p>
                    <p>小小标题标题</p>
                    <p>小小标题标题标题</p>
                    <div>
                        查看更多
                        <img src="../assets/img/ckgd.png" alt="">
                        <img src="../assets/img/ckgd2.png" alt="">
                    </div>
                </div>
                <div>
                    <img src="../assets/img/help_1.png" alt="">
                    <h2>结算标准</h2>
                    <h4></h4>
                    <p>小小标题标题标题</p>
                    <p>小小标题标题</p>
                    <p>小小标题标题</p>
                    <div>
                        查看更多
                        <img src="../assets/img/ckgd.png" alt="">
                        <img src="../assets/img/ckgd2.png" alt="">
                    </div>
                </div>
                <div>
                    <img src="../assets/img/help_1.png" alt="">
                    <h2>大打标题</h2>
                    <h4></h4>
                    <p>小小标题</p>
                    <p>小小标题标题</p>
                    <p>小小标题</p>
                    <div>
                        查看更多
                        <img src="../assets/img/ckgd.png" alt="">
                        <img src="../assets/img/ckgd2.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        methods: {
            handleToDetail(e) {
                if(e.target.nodeName === 'P' || e.target.innerText == '查看更多 '){
                    this.$router.push('/helpcenterdetail')
                }
            }
        },
    }
</script>

<style lang="less" scoped>
    @import url('../assets/css/theme-color.less');
    .helpcenter{
        position: relative;
        .banner{
            background:url('../assets/img/type-banner-bg.png');
            background-size: contain;
            .banner-container{
                width: 1200px;
                margin: 0 auto;
                position: relative;
                display: flex;
                align-items: center;
                height: 430px;
                img{
                    position: absolute;
                    top: 27px;
                    right: 33px;
                    width: 520px;
                }
                >div{
                    h2{
                        font-size: 40px;
                        font-weight: 600;
                    }
                    h4{
                        background: @themeColor;
                        width: 32px;
                        height: 4px;
                        margin: 12px 0 24px;
                    }
                    p{
                        font-size: 18px;
                        width: 530px;
                    }
                }
            }
        }
        .content{
            background: #f6f7f8;
            .content-container{
                width: 1200px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                padding: 100px 0;
                >div{
                    width: 386px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background: #fefefe;
                    padding: 30px;
                    border-radius: 8px;
                    box-shadow: 5px 5px 10px #cde;
                    transition: .3s;
                    cursor: pointer;
                    >img{
                        width: 100px;
                    }
                    >h2{
                        font-size: 28px;
                        font-weight: 600;
                        margin: 30px 0 8px;
                    }
                    >h4{
                        width: 36px;
                        height: 2px;
                        background: @themeColor;
                        margin-bottom: 30px;
                    }
                    >p{
                        font-size: 14px;
                        color: #898989;
                        margin: 8px;
                        transition: .2s;
                        &:hover{
                            color: @themeColor;
                        }
                    }
                    >div{
                        color: #2c2c2c;
                        margin: 30px;
                        transition: .2s;
                        img{
                            width: 22px;
                            transition: .3s;
                        }
                        img:nth-of-type(2){
                            display: none;
                        } 
                    }
                    &:hover{
                        transform: translateY(-10px);
                        box-shadow: 8px 8px 10px #cde;
                        >div{
                            color: @themeColor;
                            img:nth-of-type(1){
                                display: none;
                            } 
                            img:nth-of-type(2){
                                left: 6px;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
        .fixed-detail-helpcenter{
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: white;
        }
    }
   
</style>